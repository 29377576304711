import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { NotificationsService } from '../NotificationsService/notifications.service';
import { NotificationMessage, NotificationType } from '../../entity/NotificationMessage';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  webmailEx: boolean = true;
  forgetPassword: boolean = true;
  service_unavailable: boolean = false;
  errorRaised: boolean = false;
  constructor(private notificationsService: NotificationsService, private injector: Injector, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(0),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        const notificationMessage = new NotificationMessage();
        if (error.status === 401) {
          if (error.error.message.includes('0792')) {
            if (!this.errorRaised) {
              const notificationMessage = new NotificationMessage();
              notificationMessage.type = NotificationType.error;
              notificationMessage.title = 'Impossible de valider votre identité';
              notificationMessage.message = 'Votre session a expiré';
              notificationMessage.timeOut = 3000;
              this.notificationsService.sendMessage(notificationMessage);
            }
            this.router.navigate(['login']);
            localStorage.clear();
            localStorage.setItem('currentRoute', this.router.url);
            document.getElementById('loadingBar').style.display = 'none';
            this.errorRaised = true;
            return throwError(() => error);
          }
          if (error.error.message === 'Password invalide pour cette adresse mail') {
            const notificationMessage = new NotificationMessage();
            notificationMessage.type = NotificationType.error;
            notificationMessage.title = 'Impossible de valider votre identité';
            notificationMessage.message = 'Merci de verifier votre mot de passe';
            notificationMessage.timeOut = 3000;
            this.notificationsService.sendMessage(notificationMessage);
            return;
          }

          if (error.error.message === "Il n'y a aucun compte associé à cette adresse mail ou Api-key " || error.error.message ==="Aucun compte n'est lié à cette adresse e-mail,API-key, ou il y a des autorisations manquantes. ") {
            const notificationMessage = new NotificationMessage();
            notificationMessage.type = NotificationType.error;
            notificationMessage.title = 'Impossible de valider votre identité';
            notificationMessage.message = "Il n'y a aucun compte associé à cette adresse mail";
            notificationMessage.timeOut = 3000;
            this.notificationsService.sendMessage(notificationMessage);
            return;
          }
          if (error.error.message?.includes('error 1592')) {
            this.router.navigate(['IpNonAutorise']);
            return;
          }
        }

        if (error.status === 503 || error.status === 504 || error.status === 505) {
          if (error.error.message === "Echec d'authentification de l'email") {
            if (this.webmailEx === true) {
              const notificationMessage = new NotificationMessage();
              notificationMessage.type = NotificationType.error;
              notificationMessage.title = 'WebMail';
              notificationMessage.message = ' Merci de verifier votre Configuration WebMail ';
              notificationMessage.timeOut = 3000;
              this.notificationsService.sendMessage(notificationMessage);
              this.webmailEx = false;
              return;
            } else {
              return;
            }
          }

          if (error.error.message === 'Failed VoIP login') {
            const notificationMessage = new NotificationMessage();
            notificationMessage.type = NotificationType.error;
            notificationMessage.title = 'VoIP';
            notificationMessage.message = ' Merci de verifier votre Configuration VoIP (Numéro de téléphone interne) ';
            notificationMessage.timeOut = 3000;
            this.notificationsService.sendMessage(notificationMessage);
            return;
          }

          // other gateway error
          if (!this.service_unavailable) {
            const notificationMessage = new NotificationMessage();
            notificationMessage.type = NotificationType.error;
            notificationMessage.title = 'Service indisponible';
            notificationMessage.message =
              'Veuillez réessayer ultérieurement ou contactez le support technique. Détails: ' + error.error.message;
            notificationMessage.timeOut = 3000;
            this.notificationsService.sendMessage(notificationMessage);
            this.service_unavailable = true;
          }
          return [error.error.message];
        }

        /* if (error.status === 409) {
          return [error.error.message];
        } */
        if (error.status === 406) {
          if (error.error.message === 'Aucun users correspond a id') {
            const notificationMessage = new NotificationMessage();
            notificationMessage.type = NotificationType.error;
            notificationMessage.title = 'Opération echoué';
            notificationMessage.message = "il n'y a aucun compte associé à cette adresse mail";
            notificationMessage.timeOut = 3000;
            this.notificationsService.sendMessage(notificationMessage);
            return;
          }
        }

        // any other unmanaged error

        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error}`;
        }
        // back end server offline or unavailable
        if (
          errorMessage?.includes('Http failure response') &&
          errorMessage?.includes('Error Code: 0') &&
          !this.service_unavailable
        ) {
          notificationMessage.type = NotificationType.error;
          notificationMessage.title = 'Services Indisponible';
          notificationMessage.message =
            'Connexion au back office impossible! Veuillez réessayer ultérieurement ou contactez le support technique.';
          notificationMessage.timeOut = 3000;
          this.notificationsService.sendMessage(notificationMessage);
          this.service_unavailable = true;
        }
        return throwError(error);
      })
    );
  }
}
