import { ApiUsersService } from 'src/app/services/ApiUsers/api-users.service';
import { ApiStatusService } from 'src/app/services/ApiStatus/api-status.service';
import { Validators , FormControl } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { startWith, map, tap, catchError } from 'rxjs/operators';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ElementRef, Output, OnDestroy ,ChangeDetectorRef } from '@angular/core';
import { ApiCampagniesService } from '../../../../../services/ApiCompagnies/api-compagnies.service';
import { OrganismeListe } from 'src/app/entity/OrganismeListe';
import { GroupUser } from 'src/app/entity/User/GroupUser';
import { UserAdminList } from 'src/app/entity/User/UserAdminList';
import { Zone } from 'src/app/entity/Zone';
import { GroupeCommision } from 'src/app/entity/GroupeCommision';
import { RoleAdmin } from 'src/app/entity/RoleAdmin';
import { UserAdmin, Acces } from 'src/app/entity/User/UserAdmin';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { Utilisateur } from '../../../../../entity/Opportunity/FilterOpportunite';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { ApiLocationServices } from 'src/app/services/ApiLocation/api-location.service';

@Component({
  selector: 'app-filtres-utilisateurs',
  templateUrl: './filtres-utilisateurs.component.html',
  styleUrls: ['./filtres-utilisateurs.component.css'],
})
export class FiltresUtilisateursComponent implements OnInit, OnChanges, OnDestroy {
  fa = fa;
  User: UserAdmin = new UserAdmin();
  Acces_Opp: Acces[] = [];
  Roles: RoleAdmin[] = [];
  Organismes: OrganismeListe[] = [];
  GroupUsers: GroupUser[] = [];
  Users: UserAdminList[] = [];
  GroupeCommissions: GroupeCommision[] = [];
  Zones: Zone[] = [];
  filtre : any
  ListeAffectationInNotIn: string = 'In';
  ListeAffectationOuEt: string = 'Ou';
  GroupeAffecteInOuEt: string = 'Ou';

  CreateurSelectionees = [];
  AffectationsSelectionees = [];
  GroupesAffectesSelectionees = [];
  GroupesCreateurSelectionees = [];
  OrganismeSelectionees = [];
  ZonesSelectionees = [];
  ServiceSelectionnees = [];

  _unsubscribeAll: Subscription[]=[];

  @Output() onPushFilterUtilisateur: EventEmitter<Utilisateur> = new EventEmitter();

  @Output() returnToparent = new EventEmitter<boolean>();
  @Input() Reset: any;

  // Mat Chips Organisme
  visible = false;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  organismeFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredOrganisme: Observable<OrganismeListe[]>;
  ListOrganisme: string[] = [];
  allOrganisme: any[] = [];

  @ViewChild('OrganismeInput') OrganismeInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoOrganisme') matAutocompleteOrganisme: MatAutocomplete;

  // Mat Chips Createur
  CreateurFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredCreateur: Observable<UserAdminList[]>;
  ListCreateur: string[] = [];
  allCreateur: any[] = [];

  @ViewChild('CreateurInput') CreateurInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoCreateur') matAutocompleteCreateur: MatAutocomplete;

  // Mat Chips Liste Affectation
  listeAffectationFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredListeAffectation: Observable<UserAdminList[]>;
  ListAffectation: string[] = [];
  allListeAffectation: any[] = [];

  @ViewChild('listeAffectationInput') listeAffectationInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoListeAffectation') matAutocompleteListeAffectation: MatAutocomplete;

  // Mat Chips Groupe Affécté
  groupeAffecteeFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredGroupeAffectee: Observable<GroupUser[]>;
  ListGroupeAffectee: string[] = [];
  // allGroupeAffectee: any[] = [];

  @ViewChild('groupeAffecteeInput') groupeAffecteeInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoGroupeAffectee') matAutocompleteGroupeAffectee: MatAutocomplete;

  // Mat Chips Groupe createur
  groupeCreateurFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredGroupeCreateur: Observable<GroupUser[]>;
  ListGroupeCreateur: string[] = [];

  @ViewChild('groupeCreateurInput') groupeCreateurInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoGroupeCreateur') matAutocompleteGroupeCreateur: MatAutocomplete;

  // Mat Chips Zones
  zoneFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredZones: Observable<Zone[]>;
  ListZones: string[] = [];
  // allZones: Zone[] = [];

  @ViewChild('ZonesInput') ZonesInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoZones') matAutocompleteZones: MatAutocomplete;

  // Mat Chips service
  serviceFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredService: Observable<Acces[]>;
  ListService: string[] = [];
  // allService: any[] = [];

  @ViewChild('serviceInput') serviceInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoService') matAutocompleteService: MatAutocomplete;
  getFilteredService: any[];

  constructor(
    private ApiCampagniesService: ApiCampagniesService,
    private apiStatusService: ApiStatusService,
    private apiUsersService: ApiUsersService,
    private ApiLocationService: ApiLocationServices,
    private chRef: ChangeDetectorRef,
  ) {}

  ngOnDestroy(): void {
    this._unsubscribeAll.forEach((subscription: Subscription)=>{
      subscription?.unsubscribe();
    })
  }

  // Organisme -----------------------------------------------------------------------------------------------
  selectedOrganisme(event: MatAutocompleteSelectedEvent): void {
    if (!this.OrganismeSelectionees.includes(event.option.value)) {
    this.ListOrganisme.push(event.option.viewValue);
    this.OrganismeSelectionees.push(event.option.value);}
    this.OrganismeInput.nativeElement.value = '';
    this.organismeFromCtrl.setValue(null);
    this.EmitFilterUtilisateur()
  }

  removeOrganisme(organisme: string): void {
    const index = this.ListOrganisme.indexOf(organisme);
    if (index >= 0) {
      this.ListOrganisme.splice(index, 1);
      this.OrganismeSelectionees.splice(index, 1);
    }
  }

  private _filterOrganisme(): OrganismeListe[] {
    let filterValue = this.organismeFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allOrganisme.filter((organisme) =>
      organisme.nom != null ? organisme.nom.toLowerCase().includes(filterValue.toLowerCase())  : null
    );
  }

  // Createur -----------------------------------------------------------------------------------------------
  selectedCreateur(event: MatAutocompleteSelectedEvent): void {
    if (!this.CreateurSelectionees.includes(event.option.value)) {
    this.ListCreateur.push(event.option.viewValue);
    this.CreateurSelectionees.push(event.option.value);}
    this.CreateurInput.nativeElement.value = '';
    this.CreateurFromCtrl.setValue(null);
    this.EmitFilterUtilisateur()
  }

  removeCreateur(Createur: string): void {
    const index = this.ListCreateur.indexOf(Createur);
    if (index >= 0) {
      this.ListCreateur.splice(index, 1);
      this.CreateurSelectionees.splice(index, 1);
    }
  }

  private _filterCreateur(): UserAdminList[] {
    let filterValue = this.CreateurFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allListeAffectation.filter((Createur) =>
    (Createur.nom != null && Createur.nom.toLowerCase().includes(filterValue)) ||
    (Createur.prenom != null && Createur.prenom.toLowerCase().includes(filterValue))
    );
  }

  // Liste Affectation -----------------------------------------------------------------------------------------------
  selectedListeAffectation(event: MatAutocompleteSelectedEvent): void {
    if (!this.AffectationsSelectionees.includes(event.option.value)) {
    this.ListAffectation.push(event.option.viewValue);
    this.AffectationsSelectionees.push(event.option.value);}
    this.listeAffectationInput.nativeElement.value = '';
    this.listeAffectationFromCtrl.setValue(null);
    this.EmitFilterUtilisateur()
  }

  removeListeAffectation(affectation: string): void {
    const index = this.ListAffectation.indexOf(affectation);
    if (index >= 0) {
      this.ListAffectation.splice(index, 1);
      this.AffectationsSelectionees.splice(index, 1);
    }
    if (this.AffectationsSelectionees.length ===0){
      this.Utilisateur.operateur_recherche_affect ='='
      this.chRef.detectChanges();
    }
  }

  private _filterListeAffectation(): UserAdminList[] {
    let filterValue = this.listeAffectationFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allListeAffectation.filter((affectation) =>
      (affectation.nom != null && affectation.nom.toLowerCase().includes(filterValue)) ||
    (affectation.prenom != null && affectation.prenom.toLowerCase().includes(filterValue))
    );
  }

  // Groupe Affectee -----------------------------------------------------------------------------------------------
  selectedGroupeAffectee(event: MatAutocompleteSelectedEvent): void {
    if (!this.GroupesAffectesSelectionees.includes(event.option.value)) {
    this.ListGroupeAffectee.push(event.option.viewValue);
    this.GroupesAffectesSelectionees.push(event.option.value);}
    this.groupeAffecteeInput.nativeElement.value = '';
    this.groupeAffecteeFromCtrl.setValue(null);
  }

  removeGroupeAffectee(groupeAffectee: string): void {
    const index = this.ListGroupeAffectee.indexOf(groupeAffectee);
    if (index >= 0) {
      this.ListGroupeAffectee.splice(index, 1);
      this.GroupesAffectesSelectionees.splice(index, 1);
    }
  }

  // private _filterGroupeAffectee(): GroupUser[] {
  //   let filterValue = this.groupeAffecteeFromCtrl.value.toString().toLowerCase();
  //   if (filterValue === null) filterValue = '';
  //   return this.allGroupeAffectee.filter(
  //     (groupeAffectee) => groupeAffectee.nom.toLowerCase().indexOf(filterValue) === 0
  //   );
  // }

  // Groupe createur -----------------------------------------------------------------------------------------------
  selectedGroupeCreateur(event: MatAutocompleteSelectedEvent): void {
    if (!this.GroupesCreateurSelectionees.includes(event.option.value)) {
    this.ListGroupeCreateur.push(event.option.viewValue);
    this.GroupesCreateurSelectionees.push(event.option.value);}
    this.groupeCreateurInput.nativeElement.value = '';
    this.groupeCreateurFromCtrl.setValue(null);
  }

  removeGroupeCreateur(groupeCreateur: string): void {
    const index = this.ListGroupeCreateur.indexOf(groupeCreateur);
    if (index >= 0) {
      this.ListGroupeCreateur.splice(index, 1);
      this.GroupesCreateurSelectionees.splice(index, 1);
    }
  }

  // private _filterGroupeCreateur(): GroupUser[] {
  //   let filterValue = this.groupeCreateurFromCtrl.value.toString().toLowerCase();
  //   if (filterValue === null) filterValue = '';
  //   return this.allGroupeAffectee.filter(
  //     (groupeCreateur) => groupeCreateur.nom.toLowerCase().indexOf(filterValue) === 0
  //   );
  // }

  // Zones -----------------------------------------------------------------------------------------------

  selectedZones(event: MatAutocompleteSelectedEvent): void {
    this.ListZones.push(event.option.viewValue);
    this.ZonesSelectionees.push(event.option.value);
    this.ZonesInput.nativeElement.value = '';
    this.zoneFromCtrl.setValue(null);
  }

  removeZones(Zones: string): void {
    const index = this.ListZones.indexOf(Zones);
    if (index >= 0) {
      this.ListZones.splice(index, 1);
      this.ZonesSelectionees.splice(index, 1);
    }
  }

  // private _filterZones(): Zone[] {
  //   let filterValue = this.zoneFromCtrl.value.toString().toLowerCase();
  //   if (filterValue === null) filterValue = '';
  //   return this.allZones.filter((Zones) => Zones.nom.toLowerCase().indexOf(filterValue) === 0);
  // }

  // Service -----------------------------------------------------------------------------------------------

  selectedService(event: MatAutocompleteSelectedEvent): void {
    if (!this.ServiceSelectionnees.includes(event.option.value)) {
    this.ListService.push(event.option.viewValue);
    this.ServiceSelectionnees.push(event.option.value);}
    this.serviceInput.nativeElement.value = '';
    this.serviceFromCtrl.setValue(null);
    this.EmitFilterUtilisateur()
  }

  removeService(serv: string): void {
    const index = this.ListService.indexOf(serv);
    if (index >= 0) {
      this.ListService.splice(index, 1);
      this.ServiceSelectionnees.splice(index, 1);
    }
  }

  // private _filterService(): any[] {
  //   let filterValue = this.serviceFromCtrl.value.toString().toLowerCase();
  //   if (filterValue === null) filterValue = '';
  //   this.getFilteredService = this.allService.filter((element) => element.libele != null);
  //   return this.getFilteredService.filter((serv) => serv.libele.toLowerCase().indexOf(filterValue) === 0);
  // }

  ngOnInit(): void {
   
    const unsubscribeUser = this.apiUsersService.getAllUsers().subscribe((Response: UserAdminList[]) => {
      this.allListeAffectation = Response;
      this.allCreateur = Response;
      if (this.filtre) {
        if (this.Utilisateur?.list_affectation.length > 0) {
          this.ListAffectation = this.allListeAffectation
            .filter(affecte => this.Utilisateur.list_affectation.includes(affecte.id))
            .map(affecteAff => {
              this.AffectationsSelectionees.push(affecteAff.id);
              return affecteAff.nom + " "+affecteAff.prenom;
            });
        }
        if (this.Utilisateur?.list_createurs.length > 0) {
          this.ListCreateur = this.allCreateur
            .filter(createur => this.Utilisateur.list_createurs.includes(createur.id))
            .map(createurAff => {
              this.CreateurSelectionees.push(createurAff.id);
              return createurAff.nom + " "+createurAff.prenom;
            });
        }
  }
    });

    // const unsubscribeStatus = this.apiStatusService
    //   .getLisServicesGet()
    //   .pipe(
    //     tap(({ CategoryStatusOpp }) => {
    //       this.allService = CategoryStatusOpp;
    //       if (this.filtre) {
    //         if (this.Utilisateur?.services.length > 0) {
    //           this.ListService = this.allService
    //             .filter(service => this.Utilisateur.services.includes(service.id))
    //             .map(serviceAff => {
    //               this.ServiceSelectionnees.push(serviceAff.id);
    //               return serviceAff.libele;
    //             });
    //         }
    //   }
    //     })
    //   )
    //   .subscribe();
    // const unsubscribeGroup = this.apiUsersService.getGroupUsers().subscribe((Response: GroupUser[]) => {
    //   this.allGroupeAffectee = Response;
      
    // });
    // const unsubscribeLocation = this.ApiLocationService.getListeZone().subscribe((Response: Zone[]) => {
    //   this.allZones = Response;
    // });
    const unsubscribeCompany = this.ApiCampagniesService.getOrganismes().subscribe((Response: OrganismeListe[]) => {
      this.allOrganisme = Response;
      if (this.filtre) {
        if (this.Utilisateur?.organismes.length > 0) {
          this.ListOrganisme = this.allOrganisme
            .filter(org => this.Utilisateur.organismes.includes(org.id))
            .map(orgAff => {
              this.OrganismeSelectionees.push(orgAff.id);
              return orgAff.nom;
            });
        }
  }
    });

    this._unsubscribeAll.push(unsubscribeUser)
    // this._unsubscribeAll.push(unsubscribeStatus)
    // this._unsubscribeAll.push(unsubscribeGroup)
    // this._unsubscribeAll.push(unsubscribeLocation)
    this._unsubscribeAll.push(unsubscribeCompany)

    this.filteredCreateur = this.CreateurFromCtrl.valueChanges.pipe(
      startWith(null),
      map((Createur: string | null) => (Createur ? this._filterCreateur() : this.allListeAffectation.slice()))
    );

    // this.filteredService = this.serviceFromCtrl.valueChanges.pipe(
    //   startWith(null),
    //   map((serv: string | null) => (serv ? this._filterService() : this.allService.slice()))
    // );

    // this.filteredGroupeCreateur = this.groupeCreateurFromCtrl.valueChanges.pipe(
    //   startWith(null),
    //   map((groupeCreateur: string | null) =>
    //     groupeCreateur ? this._filterGroupeCreateur() : this.allGroupeAffectee.slice()
    //   )
    // );

    // this.filteredGroupeAffectee = this.groupeAffecteeFromCtrl.valueChanges.pipe(
    //   startWith(null),
    //   map((groupeAffectee: string | null) =>
    //     groupeAffectee ? this._filterGroupeAffectee() : this.allGroupeAffectee.slice()
    //   )
    // );

    this.filteredListeAffectation = this.listeAffectationFromCtrl.valueChanges.pipe(
      startWith(null),
      map((Createur: string | null) => (Createur ? this._filterListeAffectation() : this.allCreateur.slice()))
    );

    this.filteredOrganisme = this.organismeFromCtrl.valueChanges.pipe(
      startWith(null),
      map((organisme: string | null) => (organisme ? this._filterOrganisme() : this.allOrganisme.slice()))
    );

    // this.filteredZones = this.zoneFromCtrl.valueChanges.pipe(
    //   startWith(null),
    //   map((Zones: string | null) => (Zones ? this._filterZones() : this.allZones.slice()))
    // );
    this.filtre = localStorage.getItem('filtre');
    this.filtre =JSON.parse(this.filtre)
    if (this.filtre) {
      this.Utilisateur=this.filtre.utilisateur
      const allApi : Observable<any>[] = [
        this.ApiCampagniesService.getOrganismes(),
        this.apiUsersService.getAllUsers()
        
      ]
      forkJoin(allApi)
          .pipe(
            catchError(error => {
              console.error('Error:', error);
              return [];
            })
          )
          .subscribe(responses => {
            this.EmitFilterUtilisateur()
          });
    }
  
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetUtilForm(changes.Reset.currentValue);
  }

  resetUtilForm(Reset) {
    if (Reset === true) {
      this.ListOrganisme = [];
      this.Utilisateur.organismes = this.OrganismeSelectionees = [];
      this.ListCreateur = [];
      this.Utilisateur.list_createurs = this.CreateurSelectionees = [];
      this.ListAffectation = [];
      this.Utilisateur.list_affectation = this.AffectationsSelectionees = [];
      this.Utilisateur.operateur_recherche_affect = '=';
      this.Utilisateur.operateur_recherche_group = '';
      this.Utilisateur.operateur_recherche_group_affet = '';
      this.ListGroupeAffectee = [];
      this.Utilisateur.groupes_affecte = this.GroupesAffectesSelectionees = [];
      this.ListGroupeCreateur = [];
      this.Utilisateur.groupes_createurs = this.GroupesCreateurSelectionees = [];
      this.ListZones = [];
      this.Utilisateur.zones = this.ZonesSelectionees = [];
      this.ListService = [];
      this.Utilisateur.services = this.ServiceSelectionnees = [];
      Reset = false;
      this.chRef.detectChanges();
      this.returnToparent.emit(Reset);
      this.onPushFilterUtilisateur.emit(this.Utilisateur);
    }
  }

  Utilisateur: Utilisateur = new Utilisateur();

  selectOptionAffect(option: string): void {
    this.Utilisateur.operateur_recherche_affect = option;
    this.chRef.detectChanges();

  }

  EmitFilterUtilisateur() {
    if (this.AffectationsSelectionees.length ===0){
      this.Utilisateur.operateur_recherche_affect ='='
      this.chRef.detectChanges();
    }
    this.Utilisateur.groupes_affecte = this.GroupesAffectesSelectionees;

    this.Utilisateur.groupes_createurs = this.GroupesCreateurSelectionees;

    this.Utilisateur.list_affectation = this.AffectationsSelectionees;

    this.Utilisateur.list_createurs = this.CreateurSelectionees;

    this.Utilisateur.organismes = this.OrganismeSelectionees;

    this.Utilisateur.services = this.ServiceSelectionnees;

    this.Utilisateur.zones = this.ZonesSelectionees;

    this.onPushFilterUtilisateur.emit(this.Utilisateur);
  }
}
