import { Validators, FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ElementRef,
  ViewChild,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { Zone } from 'src/app/entity/Zone';

import * as fa from '@fortawesome/free-solid-svg-icons';
import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import { FilterOpportunite, FiltreProspects, tags_pros, zones } from 'src/app/entity/Opportunity/FilterOpportunite';
import { tap, startWith, map, catchError } from 'rxjs/operators';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { ApiLocationServices } from 'src/app/services/ApiLocation/api-location.service';

@Component({
  selector: 'app-filtres-prospects',
  templateUrl: './filtres-prospects.component.html',
  styleUrls: ['./filtres-prospects.component.css'],
})
export class FiltresProspectsComponent implements OnInit, OnChanges, OnDestroy {
  fa = fa;
  filtre : any 
  @Output() onPushFilterProspec: EventEmitter<FiltreProspects> = new EventEmitter();
  @Input() onRefreshFilterProspec: FiltreProspects;
  Opportunity = new FilterOpportunite();
  prospect: FiltreProspects = new FiltreProspects();
  ListVille: string;
  ZonesSelectionees: any[] = [];
  CodePostal = '01200';
  tags_pros: tags_pros[] = [];
  tagsProsSelected: any[] = [];
  @Output() returnToparent = new EventEmitter<boolean>();
  @Input() Reset: any;
  // Mat Chips Tags Prospect
  tagsProsFromCtrl: FormControl = new FormControl('', [Validators.required]);
  visible = false;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredTagsPros: Observable<tags_pros[]>;
  ListTagsPros: string[] = [];
  allTagsPros: any[] = [];
  @ViewChild('TagsProsInput') TagsProsInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoTagsPros') matAutocompleteTagsPros: MatAutocomplete;
  // Mat Chips Zones
  zoneFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredZones: Observable<Zone[]>;
  ListZones: string[] = [];
  allZones: Zone[] = [];
  _unsubscribeAll: Subscription[] = [];

  @ViewChild('ZonesInput') ZonesInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoZones') matAutocompleteZones: MatAutocomplete;

  constructor(private apiOpportuniteService: ApiOpportunitService, private ApiLocationService: ApiLocationServices,  private cdr: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this._unsubscribeAll.forEach((subscription: Subscription) => {
      subscription?.unsubscribe();
    });
  }

  // Tags Prospect -----------------------------------------------------------------------------------------------

  selectedTagsProspect(event: MatAutocompleteSelectedEvent): void {
    if (!this.tagsProsSelected.includes(event.option.value)) {
    this.ListTagsPros.push(event.option.viewValue);
    this.tagsProsSelected.push(event.option.value);}
    this.TagsProsInput.nativeElement.value = '';
    this.tagsProsFromCtrl.setValue(null);
    this.EmitFilterProspec()
  }

  removeTagsProspect(tagsPros: string): void {
    const index = this.ListTagsPros.indexOf(tagsPros);
    if (index >= 0) {
      this.ListTagsPros.splice(index, 1);
      this.tagsProsSelected.splice(index, 1);
    }
  }

  private _filterTagsProspect(): tags_pros[] {
    let filterValue = this.tagsProsFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allTagsPros.filter((tagsPros) => tagsPros.libelle.toLowerCase().includes(filterValue) );
  }

  // Zones -----------------------------------------------------------------------------------------------

  selectedZones(event: MatAutocompleteSelectedEvent): void {
    this.ListZones.push(event.option.viewValue);
    this.ZonesSelectionees.push(event.option.value);
    this.ZonesInput.nativeElement.value = '';
    this.zoneFromCtrl.setValue(null);
  }

  removeZones(Zones: string): void {
    const index = this.ListZones.indexOf(Zones);
    if (index >= 0) {
      this.ListZones.splice(index, 1);
      this.ZonesSelectionees.splice(index, 1);
    }
  }

  private _filterZones(): Zone[] {
    let filterValue = this.zoneFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allZones.filter((Zones) => Zones.nom.toLowerCase().indexOf(filterValue) === 0);
  }

  ngOnInit(): void {
    // const unsubscribeLocation = this.ApiLocationService.getListeZone().subscribe((data: Zone[]) => {
    //   this.allZones = data;
    // });
    // this._unsubscribeAll.push(unsubscribeLocation);

    this.filteredTagsPros = this.tagsProsFromCtrl.valueChanges.pipe(
      startWith(null),
      map((tagsPros: string | null) => (tagsPros ? this._filterTagsProspect() : this.allTagsPros.slice()))
    );

    this.filteredZones = this.zoneFromCtrl.valueChanges.pipe(
      startWith(null),
      map((Zones: string | null) => (Zones ? this._filterZones() : this.allZones.slice()))
    );

    this.getDetailsFilter();
    this.prospect = this.onRefreshFilterProspec;
    this.filtre = localStorage.getItem('filtre');
    this.filtre =JSON.parse(this.filtre)
    if (this.filtre) {
      this.prospect=this.filtre.prospect
      const allApi : Observable<any>[] = [
        this.apiOpportuniteService.getDetailsFilter()
        
      ]
      forkJoin(allApi)
          .pipe(
            catchError(error => {
              console.error('Error:', error);
              return [];
            })
          )
          .subscribe(responses => {
            this.EmitFilterProspec()
          });
      
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetProsForm(changes?.Reset?.currentValue);
    this.cdr.detectChanges();
  }

  resetProsForm(Reset) {
    if (Reset === true) {
      this.prospect.nom_prenom = '';
      this.prospect.RS = '';
      this.prospect.telephone = '';
      this.prospect.adresse_mail = '';
      this.prospect.ville_CP = '';
      this.ListTagsPros = [];
      this.prospect.tags_propect = this.tagsProsSelected = [];
      this.ListZones = [];
      this.prospect.zones = this.ZonesSelectionees = [];
      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushFilterProspec.emit(this.prospect);
    }
  }

  getDetailsFilter() {
    const unsubscribeOpportunite = this.apiOpportuniteService
      .getDetailsFilter()
      .pipe(
        tap(({ tags }) => {
          this.allTagsPros = tags ;
          if (this.filtre) {
            if (this.prospect?.tags_propect.length > 0) {
              this.ListTagsPros = this.allTagsPros
                .filter(tag => this.prospect?.tags_propect.includes(tag.id))
                .map(tagAff => {
                  this.tagsProsSelected.push(tagAff.id);
                  return tagAff.libelle
                });
            }}
        })
      )
      .subscribe();
    this._unsubscribeAll.push(unsubscribeOpportunite);
  }

  affiche() {}

  onItemChange() {}

  EmitFilterProspec() {
    this.prospect.zones = this.ZonesSelectionees;

    this.prospect.tags_propect = this.tagsProsSelected;

    this.onPushFilterProspec.emit(this.prospect);
  }
}
